/*
 * Due to this bug: https://github.com/styled-components/styled-components/issues/2900
 * we have to keep all our font-face declarations here.
 */

@font-face {
  font-family: 'SpeziaNormal-Upright';
  src: url('/fonts/Spezia_Web_Normal_Upright.woff') format('woff');
  src: url('/fonts/Spezia_Web_Normal_Upright.woff2') format('woff2');
}

@font-face {
  font-family: 'SpeziaNormal-Italic';
  src: url('/fonts/Spezia_Web_Normal_Italic.woff') format('woff');
  src: url('/fonts/Spezia_Web_Normal_Italic.woff2') format('woff2');
}
